<template>
  <div>
    <v-list
      two-line
      subheader
    >
      <v-subheader>MATLAB </v-subheader>

      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-code-greater-than</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>MATLAB</v-list-tile-title>
          <v-list-tile-sub-title>Execute code to generate plot</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-btn
            icon
            ripple
          >
            <v-icon
              @click="writeCodeToClipboard(matlabCode)"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-card-text
      v-if="writeRun"
      class="pt-0 mt-0"
    >
      <pre v-highlightjs><code class="matlab">{{ matlabCode }}</code></pre>
    </v-card-text>
  </div>
</template>

<script>
import { EventBus } from '../../scripts/event_bus'

import { mapGetters } from 'vuex'
export default {
  name: 'CorrectedSpectrum',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      matlabCode: '',
      writeRun: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        this.writeMatlab()
      },
      deep: true
    }
  },
  mounted () {
    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)

    this.writeMatlab()
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    writeMatlab: function () {
      this.writeRun = false
      const dbs = []
      const aos = []
      let fileid = 0
      let collid = 0

      if (this.data.files) {
        if (this.data.files[0]) {
          fileid = this.data.files[0].fileid
          collid = this.data.files[0].colluuid
          if (this.data.files[0].aos) {
            this.data.files[0].aos.forEach(function (element) {
              aos.push("'" + element.ao_id + "'")
              dbs.push("'" + element.ltpda + "'")
            })
          }
        }
      }

      // current settings

      // let ramp = ''
      // let variable = ''
      // let xlabel = ''
      // let xunit = ''
      // let mode = ''
      if (this.data.current_type !== 'constant') {
        if (this.data.current_shape === 'pulse') {
          // variable = 'LED drive current set voltage [V]'
          // xlabel = 'LED drive current set voltage'
          // xunit = 'V'
          // mode = 'pulsecurrent'
          // if (this.data.current_type === 'linramp') {
          //   ramp = 'linspace(' + this.data.current_min + ',' + this.data.current_max + ',' + this.data.current_steps + ')'
          // } else if (this.data.current_type === 'logramp') {
          //   ramp = 'logspace(log10(' + this.data.current_min + '),log10(' + this.data.current_max + '),' + this.data.current_steps + ')'
          // }
        } else {
          // variable = 'LED drive current [A]'
          // xlabel = 'LED drive current'
          // xunit = 'A'
          // mode = 'current'
          // if (this.data.current_type === 'linramp') {
          //   ramp = 'linspace(' + this.data.current_min / 1000 + ',' + this.data.current_max / 1000 + ',' + this.data.current_steps + ')'
          // } else if (this.data.current_type === 'logramp') {
          //   ramp = 'logspace(log10(' + this.data.current_min / 1000 + '),log10(' + this.data.current_max / 1000 + '),' + this.data.current_steps + ')'
          // }
        }
      } else if (this.data.pulse_type !== 'constant') {
        // variable = 'Pulses per ' + this.data.pulse_frequency + 'seconds'
        // xlabel = 'Pulses per ' + this.data.pulse_frequency + 'seconds'
        // xunit = '1/s'
        // mode = 'pulses'
        // if (this.data.pulse_type === 'linramp') {
        //   ramp = 'linspace(' + this.data.pulses_min + ',' + this.data.pulses_max + ',' + this.data.pulses_steps + '); x = round(x)'
        // } else if (this.data.pulse_type === 'logramp') {
        //   ramp = 'logspace(log10(' + this.data.pulses_min + '),log10(' + this.data.pulses_max + '),' + this.data.pulses_steps + '); x = round(x)'
        // }
      } else if (this.data.duty_type !== 'constant') {
        // variable = 'Duty cycle [%]'
        // xlabel = 'Duty cycle [%]'
        // xunit = '1'
        // mode = 'duty'
        // if (this.data.duty_type === 'linramp') {
        //   ramp = 'linspace(' + this.data.duty_min + ',' + this.data.duty_max + ',' + this.data.duty_steps + '); x = round(x)'
        // } else if (this.data.duty_type === 'logramp') {
        //   ramp = 'logspace(log10(' + this.data.duty_min + '),log10(' + this.data.duty_max + '),' + this.data.duty_steps + '); x = round(x)'
        // }
      }
      this.matlabCode = 'clear all;\nclose all;\n\n' +
        '% Get Spectra\n' +
        'db = {' + dbs.join(', ') + '};\n' +
        'uuids = {' + aos.join(', ') + '};\n' +
        '\n' +
        'aos = [];\n' +
        'for ii = 1:numel(uuids)\n' +
        'aos = [aos ao(plist(\'hostname\', \'localhost\', ...\n' +
        this.data.ltpda +
        '\'database\', db{ii}, ...\n' +
        '\'uuid\', uuids{ii}))];\n' +
        'end\n' +
        '\n' +
        '%unpack data\n' +
        'LEDvoltage = [];\n' +
        'Ch2 = [];\n' +
        'Trigger = [];\n' +
        'PMT = [];\n' +
        '\n' +
        'for i = 1:numel(aos)\n' +
        '    if contains(aos(i).description,\'Oscilliscope trace of PMT\')\n' +
        '        PMT = [PMT; aos(i).data.y];\n' +
        '        PMTfs = aos(i).data.fs;\n' +
        '        PMTdesc = aos(i).description;\n' +
        '        PMTname = aos(i).name;\n' +
        '        PMTyunits = aos(i).yunits;\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'Oscilliscope trace of Trigger\')\n' +
        '        Trigger = [Trigger; aos(i).data.y];\n' +
        '        Triggerfs = aos(i).data.fs;\n' +
        '        Triggerdesc = aos(i).description;\n' +
        '        Triggername = aos(i).name;\n' +
        '        Triggeryunits = aos(i).yunits;\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'Oscilliscope trace of Ch2\')\n' +
        '        Ch2 = [Ch2; aos(i).data.y];\n' +
        '        Ch2fs = aos(i).data.fs;\n' +
        '        Ch2desc = aos(i).description;\n' +
        '        Ch2name = aos(i).name;\n' +
        '        Ch2yunits = aos(i).yunits;\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'Oscilliscope trace of LED_Voltage\')\n' +
        '        LEDvoltage = [LEDvoltage; aos(i).data.y];\n' +
        '        LEDvoltagefs = aos(i).data.fs;\n' +
        '        LEDvoltagedesc = aos(i).description;\n' +
        '        LEDvoltagename = aos(i).name;\n' +
        '        LEDvoltageyunits = aos(i).yunits;\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'Average LED forward voltage\')\n' +
        '        fvoltage = aos(i);\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'Average LED current\')\n' +
        '        ledcurrent = aos(i);\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'PMT pulse width\')\n' +
        '        pwidth = aos(i);\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'Latency between trigger and PMT pulse\')\n' +
        '        latency = aos(i);\n' +
        '    end\n' +
        '    if contains(aos(i).description,\'PMT pulse rise time\')\n' +
        '        risetime = aos(i);\n' +
        '    end\n' +
        'end\n' +
        '\n' +
        '%create combined objects\n' +
        'PMTao = ao(plist(\'yvals\', PMT, \'fs\', PMTfs));\n' +
        'PMTao.setName(PMTname);\n' +
        'setYunits(PMTao,PMTyunits);\n' +
        'PMTao.setDescription(PMTdesc);\n' +
        'Triggerao = ao(plist(\'yvals\', Trigger, \'fs\', Triggerfs));\n' +
        'Triggerao.setName(Triggername);\n' +
        'setYunits(Triggerao,Triggeryunits);\n' +
        'Triggerao.setDescription(Triggerdesc);\n' +
        'Ch2ao = ao(plist(\'yvals\', Ch2, \'fs\', Ch2fs));\n' +
        'Ch2ao.setName(Ch2name);\n' +
        'setYunits(Ch2ao,Ch2yunits);\n' +
        'Ch2ao.setDescription(Ch2desc);\n' +
        'LEDvoltageao = ao(plist(\'yvals\', LEDvoltage, \'fs\', LEDvoltagefs));\n' +
        'LEDvoltageao.setName(LEDvoltagename);\n' +
        'setYunits(LEDvoltageao,LEDvoltageyunits);\n' +
        'LEDvoltageao.setDescription(LEDvoltagedesc);\n' +
        '\n' +
        'iplot(Triggerao, LEDvoltageao, PMTao);\n' +
        '\n' +
        'iplot([ledcurrent fvoltage]);\n' +
        '\n' +
        'pl = plist(\'autoerrors\', true, \'ERRORBARTYPE\', \'area\');\n' +
        'iplot([pwidth latency risetime],pl);\n' +
        '\n' +
        '% UPLOAD LTPDA AND SUBMIT AO TO CMSdb\n' +
        '\n' +
        '% create data file\n' +
        'fileID = fopen(\'xy.txt\', \'w\');\n' +
        '\n' +
        '%write key labels\n' +
        '    fprintf(fileID, \'"%s" \', \'LED current set voltage [V]\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Drive Current [mA]\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Voltage [V]\');\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(ledcurrent.data.x)\n' +
        '    fprintf(fileID, \'%e \', ledcurrent.data.x(i));\n' +
        '    fprintf(fileID, \'%e \', ledcurrent.data.y(i));\n' +
        '    fprintf(fileID, \'%e \', fvoltage.data.y(i));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        'fclose(fileID);\n' +
        '\n' +
        '% read file content and submit to CMSdb\n' +
        'filetext = fileread(\'xy.txt\');\n' +
        '\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'1\', \'\', filetext, \'' + this.userData.api_key + '\')\n' +
        '\n' +
        '% submit a \'[ledcurrent fvoltage]\' array containing the pulse property current and voltage objects to the LTPDA database\n' +
        '\n' +
        'pulseprop_det = submit([ledcurrent fvoltage], ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'pulse_prop\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': Pulse properties ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', \'Pulse properties (current and voltage)\', ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
        '\n' +
        '% upload pulse properties AOs to CMSdb\n' +
        '\n' +
        'submit_aolist(pulseprop_det, \'' + this.data.files[0].name + '\', [ledcurrent fvoltage], \'A1\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
        '\n' +
        '% create data file\n' +
        'fileID = fopen(\'xy2.txt\', \'w\');\n' +
        '\n' +
        '%write key labels\n' +
        '    fprintf(fileID, \'"%s" \', \'LED current set voltage [V]\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Pulsewidth\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Pulsewidth dy\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Latency\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Latency dy\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Risetime\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': Risetime dy\');\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        '   \n' +
        'for i = 1:numel(ledcurrent.data.x)\n' +
        '    fprintf(fileID, \'%e \', pwidth.data.x(i));\n' +
        '    fprintf(fileID, \'%e \', pwidth.data.y(i));\n' +
        '    fprintf(fileID, \'%e \', pwidth.data.dy(i));\n' +
        '    fprintf(fileID, \'%e \', latency.data.y(i));\n' +
        '    fprintf(fileID, \'%e \', latency.data.dy(i));\n' +
        '    fprintf(fileID, \'%e \', risetime.data.y(i));\n' +
        '    fprintf(fileID, \'%e \', risetime.data.dy(i));\n' +
        '    fprintf(fileID, \'\\n\');\n' +
        'end\n' +
        'fclose(fileID);\n' +
        '\n' +
        '% read file content and submit to CMSdb\n' +
        'filetext = fileread(\'xy2.txt\');\n' +
        '\n' +
        'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'2\', \'\', filetext, \'' + this.userData.api_key + '\')\n' +
        '\n' +
        '% submit a \'[pwidth latency risetime]\' array containing the pulse timing analysis objects to the LTPDA database\n' +
        '\n' +
        'pulsetime_det = submit([pwidth latency risetime], ...\n' +
        'plist(\'hostname\', \'localhost\', ...\n' +
        '\'database\', \'pulse_time\', ...\n' +
        '\'no dialog\', 1, ...\n' +
        this.data.ltpda +
        '\'experiment title\', \'' + this.led.internal_serialnumber + ': Pulse timing ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
        '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
        '\'analysis description\', \'Pulse timing details\', ...\n' +
        '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
        ');\n' +
        '\n' +
        '% upload pulse timing AOs to CMSdb\n' +
        '\n' +
        'submit_aolist(pulsetime_det, \'' + this.data.files[0].name + '\', [pwidth latency risetime], \'A2\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
      '\n'
      this.writeRun = true
    }
  }
}
</script>
<style scoped>
</style>
